import request from '@/api/service'
import qs from 'qs'

const api = {
  // 设置夜审
  setNightTrial: `/pms/nightTrial/setNightTrial`,
  // 获取当前酒店夜审设置配置
  getNightTrial: `/pms/nightTrial/getNightTrial/`,
  // 夜审记录(分页)
  getPage: '/pms/nightTrial/getPage',
  // 重新夜审用表单提交
  nightTrialTotal: '/pms/nightTrial/nightTrialTotal',
  // 夜审日志列表
  getList: 'pms/nightTrialLog/getList',
  // 异常订单(分页)
  getbookInfoPage: '/pms/nightTrialbookInfo/getPage',
  // 夜审日报
  getBillDaily: '/pms/nightTrial/billDaily',
  // 导出夜审日报
  exportBillDaily:'/pms/nightTrial/billDaily/export'
}

// 设置夜审
export function setNightTrial(data) {
  return request({
    url: api.setNightTrial,
    method: 'post',
    data,
  })
}

// 获取当前酒店夜审设置配置
export function getNightTrial(hotelId) {
  return request({
    url: api.getNightTrial + hotelId,
    method: 'get',
  })
}

// 夜审记录(分页)
export function getPage(params, data) {
  return request({
    url: api.getPage,
    method: 'post',
    params,
    data,
  })
}

// 重新夜审用表单提交
export function nightTrialTotal(data) {
  return request({
    url: api.nightTrialTotal,
    method: 'post',
    data,
  })
}

// 夜审日志列表
export function getList(data) {
  return request({
    url: api.getList,
    method: 'post',
    data,
  })
}

// 异常订单(分页)
export function getbookInfoPage(params, data) {
  return request({
    url: api.getbookInfoPage,
    method: 'post',
    params,
    data,
  })
}

// 夜审日报
export function getBillDaily(params) {
  return request({
    url: api.getBillDaily,
    method: 'get',
    params,
  })
}

// 导出夜审日报
export function exportBillDaily(params) {
  return request({
    url: api.exportBillDaily,
    method: 'get',
    params,
    responseType: 'blob'
  })
}
