<!-- 夜审日报 -->
<template>
  <section class="cont nightAuditDaily">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="cont-box">
      <div class="flex">
        <div>
          <span class="m-right-15">营业时间</span>
          <el-date-picker v-model="date" type="date" value-format="yyyy-MM-dd" :picker-options="pickerOptions" placeholder="选择日期" :clearable="false" @change="getData"></el-date-picker>
        </div>
        <el-alert title="已成功夜审" type="success" show-icon class="width-500" v-if="bool"></el-alert>
        <el-alert title="夜审失败无夜审日报" type="error" description="汇总夜审成功后的营业日报表（暂时仅支持客房订单）" show-icon class="width-500" v-else></el-alert>
      </div>
      <div class="title">{{ title }}</div>
    </el-row>
    <el-row class="cont-box">
      <div class="m-bottom-20">收银日报</div>
      <div class="cashierDaily">
        <div style="width: 45%">
          <el-table :data="receivableData" :stripe="true" :header-cell-style="{ textAlign: 'center' }">
            <el-table-column label="今日应收">
              <el-table-column prop="type" label="房费类型" align="center"></el-table-column>
              <el-table-column label="金额" align="center">
                <template slot-scope="scope">￥{{ scope.row.num || '0' }}</template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div style="width: 45%">
          <el-table :data="receivedData" :stripe="true" :header-cell-style="{ textAlign: 'center' }">
            <el-table-column label="今日已收">
              <el-table-column label="支付方式" align="center">
                <template slot-scope="scope">{{ scope.row.paymentMethod | filterPaymentMethod }}</template>
              </el-table-column>
              <el-table-column label="金额" align="center">
                <template slot-scope="scope">￥{{ scope.row.amount }}</template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="line"></div>
    </el-row>
    <el-row class="content-box p-top-20 p-bottom-20">
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <span class="l-height-40">夜审日报</span>
          <el-row class="right-btn">
            <el-button class="m-left-15" type="primary" @click="exportBillDaily" :disabled="!dailyData.length"><span>导出明细</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="dailyData" border fit stripe>
          <el-table-column prop="bookOrderNo" label="订单号" min-width="120"></el-table-column>
          <el-table-column prop="realName" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="预定电话"></el-table-column>
          <el-table-column label="订单来源">
            <template slot-scope="scope">
              {{ scope.row.channel | filterChannel(that) }}
            </template>
          </el-table-column>
          <el-table-column label="房费">
            <template slot-scope="scope">￥{{ scope.row.roomAmount }}</template>
          </el-table-column>
          <el-table-column label="其它消费">
            <template slot-scope="scope">￥{{ scope.row.consumptionAmount || '0' }}</template>
          </el-table-column>
          <el-table-column label="合计应收">
            <template slot-scope="scope">￥{{ scope.row.amountsPayable }}</template>
          </el-table-column>
          <el-table-column label="已收金额">
            <template slot-scope="scope">￥{{ scope.row.actualAmount }}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="nightAuditLog(scope.row)">
                <el-link type="primary">查看订单</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>
    <!-- 订单详情 抽屉 -->
    <OrderDetails :orderDetailsBool="orderDetailsBool" :bookId="bookId" @closeOrderDetails="closeOrderDetails"></OrderDetails>
  </section>
</template>

<script>
  import { getBillDaily, exportBillDaily } from '@/api/pms/nightAudit/nightAudit'
  import OrderDetails from '@/components/local/atrialState/OrderDetails'
  import { dateFactory, exportFile } from '@/common/js/common'
  import { mapState } from 'vuex'
  export default {
    name: 'nightAuditDaily',
    components: { OrderDetails },
    data() {
      return {
        crumbs: new Map([['PMS'], ['夜审中心'], ['夜审日报']]),
        that: this,
        bool: false,             // 夜审状态
        date: dateFactory.getDistanceToday(-1,false),
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() >= Date.now()
          },
        },
        receivableData: [],
        receivedData: [],
        dailyData: [],

        // 订单详情 抽屉
        orderDetailsBool: false, // 是否显示 订单详情 抽屉
        bookId: '',              // 订单id
      }
    },
    computed: {
      ...mapState(['dictData', 'hotelInfo']),
      title() {
        let arr = this.date.split('-')
        return `${arr[1] * 1}月${arr[2] * 1}日夜审日报`
      },
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.bool = false
        this.receivableData = []
        this.receivedData = []
        this.dailyData = []
        let data = {
          date: this.date,
          hotelId: this.hotelInfo.id,
        }
        getBillDaily(data).then(({ success, records }) => {
          if (success) {
            this.bool = true
            this.receivableData = [
              { type: '全日房订单', num: '0' },
              { type: '钟点房订单', num: '0' },
              { type: '总计应收', num: '0' },
            ]
            this.receivableData[0].num = records.fullTimeAmount
            this.receivableData[1].num = records.hourRoomAmount
            this.receivableData[2].num = records.totalAmount
            this.dailyData = records.bookRoomList
            this.receivedData = records.paymentAmounts
            if (this.receivedData.length) {
              let count = 0
              this.receivedData.forEach((el) => {
                count += el.amount
              })
              this.receivedData.push({ paymentMethod: 'count', amount: count })
            }
          }
        })
      },
      // 导出明细
      exportBillDaily() {
        let data = {
          date: this.date,
          hotelId: this.hotelInfo.id,
        }
        exportBillDaily(data).then((res) => {
          exportFile(res, this.title)
        })
      },
      // 查看订单
      nightAuditLog(item) {
        this.orderDetailsBool = true
        this.bookId = item.id
      },
      // 关闭 订单详情 抽屉
      closeOrderDetails(val, bookId) {
        this.orderDetailsBool = val
        this.bookId = bookId
        this.getData()
      },
    },
    filters: {
      // 渠道
      filterChannel(val, that) {
        if (val && that.dictData.hasOwnProperty('order-source-list')) {
          if (that.dictData['order-source-list'].length) {
            that.dictData['order-source-list'].forEach((item) => {
              if (item.value === val) val = item.label
            })
            return val
          }
        }
      },
      // 支付方式
      filterPaymentMethod(val) {
        switch (val) {
          case 'WECHAT':
            return '微信支付'
          case 'ALIPAY':
            return '支付宝'
          case 'UNIONPAY_CARD':
            return '银联卡'
          case 'CREDIT_CARD':
            return '信用卡'
          case 'CASH':
            return '现金'
          case 'DOUYIN_APPLET':
            return '抖音小程序'
          case 'DEPOSIT_CARD':
            return '储值卡'
          case 'YSE':
            return '银盛通'
          case 'CFP':
            return '云闪付'
          case 'MEITUAN':
            return '美团代收'
          case 'XIECHENG':
            return '携程代收'
          case 'OTHER':
            return '其他代收'
          case 'BGC':
            return '黑金卡'
          case 'CREDIT':
            return '挂账'
          case 'PRE_LICENSING':
            return '预授权'
          case 'YZT':
            return '银账通'
          case 'count':
            return '总计已收'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cont-box {
    background: #ffffff;
    padding: 20px 50px;
    margin-bottom: 10px;
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
    .cashierDaily {
      display: flex;
      justify-content: space-between;
      /* 表格 */
      /deep/ .el-table {
        thead {
          .el-table__cell {
            font-size: 17px;
            font-weight: 400;
            background: #f4f4f4;
            color: #2d2d2d;
          }
        }
      }
    }
    .line {
      width: 1px;
      height: 100%;
      background: #7c7c7c;
      position: absolute;
      top: 0;
      right: 50%;
    }
  }
</style>
